import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import HeroBanner from "../components/hero-banner"
import Slideshow from "../components/slideshow"
import Img from "gatsby-image"
import { FaExternalLinkAlt } from 'react-icons/fa';

export default ({ data }) => (
  <>
    <Layout selectedLink="1" footerFacebookLink="https://www.facebook.com/traleemarathonkerry/">
      <SEO title="Tralee International Marathon 2024" description="Official Tralee Marathon 2024 Website. Join us 9 March 2024 for our full marathon." />
      <HeroBanner
        bgImage={data.image1.childImageSharp.fluid}
        title="Tralee Full Marathon 2024"
        subTitle="9 March 2024"
        linkTitle="Register Today!"
        link="https://eventmaster.ie/event/8BAeH7qTV1"
      />

<section className="main-introduction">
        <div className="container-width">
          <div className="col-1">
            <p class="intro">
            The Tralee Marathon 2024 is a 26.2 mile road race that will be held in Tralee and the surrounding countryside of North Kerry.
            </p>
            <p>
            We will be honoring St. Brigid, who is one of the three patron saints of Ireland. 2024 marks the 1500th anniversary of the saint's death, and to commemorate this special occasion, every participant of the 2024 Tralee Marathon will receive a special commemorative St. Brigid finisher medal.
            </p>
          </div>
          <div className="col-2">
            <div className="fb-iframe">
              <iframe class="fb-iframe__iframe" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ftraleemarathonkerry%2F&tabs=timeline&width=400&height=500&small_header=true&adapt_container_width=true&lazy=true&hide_cover=true&show_facepile=true&appId" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            </div>
          </div>
        </div>
      </section>

      <section className="faq">
        <h3>Full Marathon FAQ</h3>
        <div className="container-width">
          <div className="col-1">
            <h4>Is the race chip timed?</h4>
            <p>Yes, the Tralee Marathon is timed by <a href="http://www.timingsolutionsireland.com/">Timing Solutions Ireland</a>.</p>
          </div>
          <div className="col-2">
            <h4>Where can I collect my number and race pack?</h4>
            <p>
              There will be a number collection the night before. Details will be emailed the week before the run.
            </p>
          </div>  
          <div className="col-1">
            <h4>Do you have a bag drop Facility?</h4>
            <p>
              Yes, collection at the start line and collection at the finish line.
            </p>
          </div>
          <div className="col-2">
            <h4>Where does the race start and finish?</h4>
            <p>The start and finish of the full marathon are shown on the map below.</p>
          </div>
          <div className="col-1">
          <h4>What time does the race start?</h4>
            <p>9:00 am.</p>
          </div>
          <div className="col-2"> 
          <h4>What is the cost?</h4>
            <p>€55 per participant.
            <br></br><small><em>* Additional bundle offers available at registration</em></small>
            </p>
          </div>

          <div className="col-1">
            <h4>Are there any special offers available?</h4>
            <p>
              Yes, The <a href="https://www.grandhoteltralee.com/">Grand Hotel Tralee</a> is offering 1 night bed & breakfast & 4 course evening meal. Book direct by calling <a href="tel:0667121499">0667121499</a>.
            </p>
          </div>
        </div>

        
      </section>

      <section className="course">
        <h3>Full Marathon Course</h3>
        <div className="button-subtle"><a href="https://youtu.be/Ss689BuLBvU" target="_blank">View Course Flythrough Video <FaExternalLinkAlt /></a></div>
        <figure>
          <div className="course__photo-holder">
            <a href="https://www.mapmyrun.com/routes/fullscreen/2763609829/" class="course__full-map-btn" target="_blank">View Interactive Map<FaExternalLinkAlt /></a>
            <Img
              className="course__photo"
              fluid={data.image3.childImageSharp.fluid}
              alt="Tralee Full Marathon 2024 Course"
            />
          </div>
          <figcaption>
            <ul>
              <li>
                The route will be the original 2013 route (Tralee, Ardfert,
                Fenit, Spa, Tralee)
              </li>
              <li>
                It will start outside the <strong>Aqua Dome</strong>, heading to{" "}
                <strong>Ardfert</strong>, Barrow, and then onto{" "}
                <strong>Fenit</strong>
              </li>
              <li>
                The route heads back to Tralee via the <strong>Spa</strong>, the{" "}
                <strong>Kerries</strong>, and <strong>Blennerville</strong>
              </li>
              <li>
                Finish line is on the <strong>Neil Armstrong Way</strong><br></br><br></br>
              </li>
            </ul>
          </figcaption>
        </figure>
      </section>

      <section className="regulations">
        <h3>Rules & Regulations</h3>
        <p><span>All rules have been established in order to protect the safety of our participant's.</span><span>Your cooperation is greatly appreciated in helping everyone to enjoy race day.</span></p>
        <div className="regulations__bullets">
          <ol>
            <li>Entry fees are non-refundable and non-transferable.</li>
            <li>
              Headphones are not permitted on the course for reasons of health &
              safety, both yours and other competitors. When you wear such a
              device you are unable to hear other competitors, cars, bicycles or
              stewards instructions.
            </li>
            <li>Marathon runners must complete the course in 7 hours.</li>
            <li>
              The decisions of the race director are final in all matters.
            </li>
            <li>Please be courteous to other road and pavement users.</li>
            <li>
              There are no road closures normal rules of the road apply , please
              follow any instructions from the stewards and Gardaí.
            </li>
            <li>
              Bottled water will be available at water stations every four miles
              along the route and at the finish line, when disposing of your
              bottles and cups do not drop them on the road in front of you
              where they can be hazardous to runners behind you.
            </li>
            <li>
              If you have to drop out you should make your way to the closest
              steward who will make contact with the appropriate personnel
              depending on your needs.
            </li>
            <li>
              Event officials reserve the right, to modify, supplement or waive
              all or part of the event rules.
            </li>
            <li>
              The course is marked in Kilometres starting with 42k or the
              signage reflects the distance left to the finish not the distance
              completed.
            </li>
          </ol>
        </div>
      </section>

      <section className="photo-gallery">
        <h3>Photo Gallery</h3>
        <Slideshow slidesFolder="slides" />
      </section>
    </Layout>
  </>
)

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "full-marathon-2021-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image2: file(relativePath: { eq: "pat-quinlan.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image3: file(relativePath: { eq: "marathon-course.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 95, srcSetBreakpoints: [ 960, 960, 960, 1280, 1920 ]) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`